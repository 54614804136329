import { ClockIcon, MapPinIcon, SparklesIcon } from "@heroicons/react/24/outline"
import { EncounteredUser } from "../api/client"
import { ColorCircle } from "./Badges"
import { formatReward } from "./formatting"

type EventType = 'steal' | 'patrol' | 'survey' | 'tossin' | 'diggin' | 'activation'

// Type definitions
export interface BaseEvent {
  id: number
  occurred_at: number
  location?: string
  event_type: EventType
}

export interface MissionParticipant {
  user: EncounteredUser
  reward: number
  buddy_bonus?: number
}

interface MissionParticipantDisplayProps {
  participant: MissionParticipant
}

export const MissionParticipantDisplay: React.FC<MissionParticipantDisplayProps> = ({ participant }) => {
    console.log("participant", participant)
  const user = participant.user
  return (
    <div className="d-flex flex-column gap-2">
      <div 
        className="p-2 rounded d-flex gap-2 align-items-center" 
        style={{ backgroundColor: user.team.color_code }}
      >
        <span>{user.name}</span>
        <span className="text-muted small">{user.public_key}</span>
      </div>
      {participant.reward && (
        <div className="d-flex align-items-center gap-1 ms-2">
					<ColorCircle color={user.team.color_code} />
          <span>{formatReward(participant.reward)}</span>
          {(participant.buddy_bonus && participant.buddy_bonus > 0) ? (
            <span className="badge bg-warning ms-1" title="Buddy Bonus">
              👥 +{participant.buddy_bonus}
            </span>
          ) : null}
        </div>
      )}
    </div>
  )
}

export interface TossinParticipantDisplayProps {
  participant: MissionParticipant
  winner: boolean
  winnerMultiplyer: number
}

export const TossinParticipantDisplay: React.FC<TossinParticipantDisplayProps> = (
  { participant, winner, winnerMultiplyer }) => {
    console.log("tossin participant", participant)
  const user = participant.user
  return (
    <div className="d-flex flex-column gap-2">
      <div 
        className="p-2 rounded d-flex gap-2 align-items-center" 
        style={{ backgroundColor: user.team.color_code }}
      >
        <span>{user.name}</span>
        <span className="text-muted small">{user.public_key}</span>
      </div>
      {participant.reward && (
        <div className="d-flex align-items-center gap-1 ms-2 mb-2">
					<ColorCircle color={user.team.color_code} />
          <span>{formatReward(participant.reward)}</span>
          {(participant.buddy_bonus && participant.buddy_bonus > 0) ? (
            <span className="badge bg-warning ms-1" title="Buddy Bonus">
              👥 +{participant.buddy_bonus}
            </span>
          ) : null}
          {winner && (
            <span className="badge bg-warning ms-1" title="Buddy Bonus">
              🏆 x{winnerMultiplyer}
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export interface UserDisplayProps {
  user: EncounteredUser
  reward: number
}

export const UserDisplay: React.FC<UserDisplayProps> = ({ user, reward }) => {
  return (
    <div className="d-flex flex-column gap-2"> 
      <div 
        className="p-2 rounded d-flex gap-2 align-items-center" 
        style={{ backgroundColor: user.team.color_code }}
      >
        <span>{user.name}</span>
        <span className="text-muted small">{user.public_key}</span>
      </div>
      {reward && (
        <div className="d-flex align-items-center gap-1 ms-2">
					<ColorCircle color={user.team.color_code} />
          <span>{reward}</span>
        </div>
      )}
    </div>
  )
}


export interface TrashReward {
  [teamId: string]: number
}

// Component to display trash rewards
export const TrashRewardDisplay: React.FC<{ rewards: TrashReward }> = ({ rewards }) => {
  const total = Object.values(rewards).reduce((sum, count) => sum + count, 0)
  if (total === 0) return null

  return (
    <div className="d-flex align-items-center gap-2">
      <SparklesIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />
      <span>{total} trash</span>
    </div>
  )
}

// Utility function to format timestamp
const formatTime = (timestamp: number): string => {
  const date = new Date(timestamp * 1000)
  const weekday = date.toLocaleString('en-US', { weekday: 'short' })
  return `${weekday} ${date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
}


export const EventFooter: React.FC<{ event: BaseEvent }> = ({ event }) => (
  <div className="d-flex gap-2 text-muted small">
    <div className="d-flex gap-1 align-items-center">
      <ClockIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />
      {formatTime(event.occurred_at)}
    </div>
    {event.location && (
      <div className="d-flex gap-1 align-items-center">
        <MapPinIcon style={{ width: '1.5rem', height: '1.5rem' }} className="me-2" />
        {event.location}
      </div>
    )}
  </div>
)
